.contacts{
    padding-bottom: 64px;
}

.contact-title{
    font-size: 40px;
    text-align: center;
    margin-bottom: 32px;
}

.contact{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0 100px;
}