.main-search-result{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.products{
  min-height: calc(100vh - 160px);
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 30px 0;
  .product-item{
    display: flex;
    justify-content: flex-start;
    max-width: 1200px;
    width: 100%;
    background-color: #fff;
    padding: 1rem;
    h1{
      font-size: 30px;
    }

    p{
      font-size: 18px;
      margin: 20px 0;
    }

    strong  {
      font-size: 25px;
      color: #fb8b24;
    }
    
    img{
      height: 200px;
      margin-right: 20px;
    }
  }
}

.search__filters{
  margin-top: 64px;
  margin-left: 20px;
  display: flex;
  flex-direction: column;
  width: 100px;
  .title{
    margin-bottom: 12px;
    h2{
      color: #8F8F9D;
      font-size: 25px;
      font-weight: 500;
    }
  }
  .toggles{
    display: flex;
    align-items: center;
    gap: 0 10px;
  }
  select{
    background-color: #fff;
    border: 1px solid #8F8F9D;
    font-size: 20px;
    font-weight: 500;
  }
}

.not-found{
  margin-top: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: start;
  gap: 20px 0;
  h2{
    span{
      color: #fb8b24;
    }
    font-size: 30px;
    font-weight: 500;
  }
  p{
    font-size: 20px;
  }
}