@import 'reset';

.container{
  max-width: 1920px;
  width: 100%;
  margin: 0 auto;
  padding: 0px 30px;
}

@media screen and (max-width: 1920px) {
    .container{
      max-width: 1920px;
    }
}

/* ubuntu-regular - cyrillic_latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Ubuntu';
  font-style: normal;
  font-weight: 400;
  src: url('./assets/fonts/ubuntu-v20-cyrillic_latin-regular.woff2') format('woff2'), /* Chrome 36+, Opera 23+, Firefox 39+ */
       url('./assets/fonts/ubuntu-v20-cyrillic_latin-regular.woff') format('woff'); /* Chrome 5+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* ubuntu-500 - cyrillic_latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Ubuntu';
  font-style: normal;
  font-weight: 500;
  src: url('./assets/fonts/ubuntu-v20-cyrillic_latin-500.woff2') format('woff2'), /* Chrome 36+, Opera 23+, Firefox 39+ */
       url('./assets/fonts/ubuntu-v20-cyrillic_latin-500.woff') format('woff'); /* Chrome 5+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* ubuntu-700 - cyrillic_latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Ubuntu';
  font-style: normal;
  font-weight: 700;
  src: url('./assets/fonts/ubuntu-v20-cyrillic_latin-700.woff2') format('woff2'), /* Chrome 36+, Opera 23+, Firefox 39+ */
       url('./assets/fonts/ubuntu-v20-cyrillic_latin-700.woff') format('woff'); /* Chrome 5+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}


body{
  font-family: 'Ubuntu';
  background-color: #ffffff;
}

