.hero {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    .hero-text {
        margin-top: 64px;
        display: flex;
        flex-direction: column;
        align-items: center;
        h1 {
            color: #fb8b24;
            font-size: 70px;
            font-weight: 600;
        }

        h2 {
            font-size: 20px;
            margin-left: 20px;
            color: #8F8F9D;
            margin-bottom: 32px;
        }

        p {
            font-size: 30px;
            margin-bottom: 64px;
        }
    }

    .go-shop_link {
        width: 200px;
        display: flex;
        text-decoration: none;
        p {
            text-align: center;
            width: 200px;
            color: #fb8b24;
            border: 1px solid #fb8b24;
            position: relative;
            z-index: 1;
            font-size: 40px;
        }

        p:after {
            content: "";
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 100%;
            transform: scaleY(0);
            transform-origin: bottom center;
            background: #fb8b24;
            z-index: -1;
            transition: transform 0.3s;
        }

        p:hover {
            color: #fff;
            transition: 1s;
        }

        p:hover::after {
            transform: scaleY(1);
        }
    }
}