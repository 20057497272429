.products{
    margin: 64px 0 ;
}
.category_products{
        display: flex;
        flex-wrap: wrap;
        gap: 15px;
}
.store__products{
    display: flex;
    gap: 0 50px;
}
.switcher{
    display: flex;
    flex-direction: column;
    width: 150px;
    height: 256px;
    border: 1px solid #8F8F9D;
    h2{
        font-size: 18px;
        padding: 10px 15px;
        background-color: rgba(176, 176, 176, 0.615);
    }
    .switch_link{
        text-decoration: none;
        color: black;
        display: flex;
        padding: 10px 0;
        padding-left: 15px;
        width: 148px;
    } 
    .switch_link:hover{
        background-color: #fb8b24;
    }
}


.product-box{
    width: 200px;
    height: 280px;
    box-shadow: 0 14px 14px gray;
    border-radius: 5px;
    .product__link{
        width: 200px;
        text-decoration: none;
        color: black;
        display: flex;
        flex-direction: column;
        text-align: start;
        .product-top{
            padding-top: 15px;
            display: flex;
            flex-direction: column;
            align-items: center;
            text-align: center;
            margin-bottom: 10px;
            .product_image{
                width: 180px;
                height: 180px;
                display: flex;
                margin-bottom: 12px;
            }
        }
    }
}
.product_bottom{
    display: flex;
    padding: 0 20px;
    justify-content: space-between;
}

.loading_img{
    width: 100px;
    height: 100px;
    margin-top: 100px;
    margin-left: 400px;
}